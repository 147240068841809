<!--
 * @FileDescription: 
 * @Author: jianguo Wang
 * @Date: 2023-12-14 15:29:31
 * @LastEditors: jianguo Wang
 * @LastEditTime: 2025-01-10 14:18:02
-->
<template>
  <div class="page-contain">
    <div class="step-contain">
      <div class="step-box">
        <div class="step step01">01</div>
        <div class="step-title">{{ $t('components.secondPart.561361-0') }}</div>
        <div class="step-tip">{{ $t('components.secondPart.561361-1') }}</div>
      </div>
      <div class="step-box">
        <div class="step step02">02</div>
        <div class="step-title">{{ $t('components.secondPart.561361-2') }}</div>
        <div class="step-tip">{{ $t('components.secondPart.561361-3') }}</div>
      </div>
      <div class="step-box">
        <div class="step step03">03</div>
        <div class="step-title">{{ $t('components.secondPart.561361-4') }}</div>
        <div class="step-tip">{{ $t('components.secondPart.561361-5') }}</div>
      </div>
    </div>

    <!-- pc -->
    <div class="pc-contain">
      <div class="contain">
        <div class="tip-contain">
          <div class="tip1">
            {{ $t('components.secondPart.561361-6') }} <br />
            {{ $t('components.secondPart.561361-7') }}
          </div>
          <div class="tip2">{{ $t('components.secondPart.561361-8') }}</div>
        </div>
        <img src="@/assets/01@3x-en.png" alt="" />
      </div>
      <div class="contain">
        <img src="@/assets/02@3x-en.png" alt="" />
        <div class="tip-contain">
          <div class="tip1">{{ $t('components.secondPart.561361-9') }}</div>
          <div class="tip2">{{ $t('components.secondPart.561361-10') }}</div>
        </div>
      </div>
      <div class="contain">
        <div class="tip-contain">
          <div class="tip1">{{ $t('components.secondPart.561361-11') }}</div>
          <div class="tip2">
            {{ $t('components.secondPart.561361-12') }}
          </div>
        </div>
        <img src="@/assets/03@3x-en.png" alt="" />
      </div>
      <div class="contain">
        <img src="@/assets/04@3x-en.png" alt="" />
        <div class="tip-contain">
          <div class="tip1">{{ $t('components.secondPart.561361-13') }}</div>
          <div class="tip2">
            {{ $t('components.secondPart.561361-14') }}
            {{ $t('components.secondPart.561361-15') }}
          </div>
        </div>
      </div>
    </div>

    <!-- 移动端 -->
    <div class="phone-contain">
      <div class="contain">
        <div class="tip-contain">
          <div class="tip1">{{ $t('components.secondPart.561361-6') }} {{ $t('components.secondPart.561361-7') }}</div>
          <div class="tip2">{{ $t('components.secondPart.561361-8') }}</div>
        </div>
        <img src="@/assets/01@3x-en.png" alt="" />
      </div>
      <div class="contain">
        <div class="tip-contain">
          <div class="tip1">{{ $t('components.secondPart.561361-9') }}</div>
          <div class="tip2">{{ $t('components.secondPart.561361-10') }}</div>
        </div>
        <img src="@/assets/02@3x-en.png" alt="" />
      </div>
      <div class="contain">
        <div class="tip-contain">
          <div class="tip1">{{ $t('components.secondPart.561361-11') }}</div>
          <div class="tip2">
            {{ $t('components.secondPart.561361-12') }}
          </div>
        </div>
        <img src="@/assets/03@3x-en.png" alt="" />
      </div>
      <div class="contain">
        <div class="tip-contain">
          <div class="tip1">{{ $t('components.secondPart.561361-13') }}</div>
          <div class="tip2">
            {{ $t('components.secondPart.561361-14') }}
          </div>
        </div>
        <img src="@/assets/04@3x-en.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "secondPart",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="./secondPartCss/max.css" scoped></style>
<style src="./secondPartCss/middle.css" scoped></style>
<style src="./secondPartCss/flat.css" scoped></style>
<style src="./secondPartCss/min.css" scoped></style>
<style scoped></style>
