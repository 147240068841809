/*
 * @FileDescription: 
 * @Author: jianguo Wang
 * @Date: 2025-01-06 15:26:56
 * @LastEditors: jianguo Wang
 * @LastEditTime: 2025-01-10 11:15:44
 */
import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);


import customZhCn from "./locale/zh.json";
import customEnUs from "./locale/en.json";

// 注册i18n实例并引入语言文件
const i18n = new VueI18n({
  // locale: localStorage.getItem("bpHomeLanguage"),
  locale: "en-US",
  messages: {
    "zh-CN": customZhCn,
    "en-US": customEnUs,
  },
  silentTranslationWarn: true
});

export default i18n;