<!--
 * @FileDescription: 
 * @Author: jianguo Wang
 * @Date: 2023-12-14 15:29:31
 * @LastEditors: jianguo Wang
 * @LastEditTime: 2025-01-10 14:54:12
-->
<template>
  <div class="page-contain">
    <div class="title">{{$t('components.Privacy.text')}}</div>
    <div class="content">
    {{ language == 'zh-CN'?text:text2 }}
    </div>
  </div>
</template>

<script>
export default {
  name: "firstPart",
  props: {
    msg: String,
  },
  mounted() {
    this.language = localStorage.getItem("bpHomeLanguage");
  },
  data() {
    return {
      language: "en-US",
      text: `⽣效⽇期：2024 年 7⽉ 01 ⽇

      
ByberPay⾮常重视⽤户个⼈信息及隐私权的保护。因此ByberPay制订了涵盖如何收集、接收、使
⽤、处理、共享、转让、公开、保存和保护⽤户信息的《隐私政策》（下称“本政策/本隐私政策”）。
本政策与您所使⽤的我们的服务息息相关，您⼀旦访问我们的⽹站、使⽤或继续使⽤我们提供的服务，或者向我
们提供您的信息，即意味着您同意我们按照本《隐私政策》收集、使⽤、处理、共享、转让、公开和保存您的个
⼈信息。希望您在浏览我们的⽹站或者接受我们的服务之前能够仔细阅读本隐私政策的全部条款，确认您已完全
了解我们对您个⼈信息的处理规则，并作出您认为适当的选择。请注意，如果您不同意接受本隐私政策相关内容
的约束，则不应使⽤我们的⽹站或服务。
本政策的“您”在特定的语境中，也包含您的⽤户，即您的⽤户以访问、电联等⽅式向您提供的个⼈信息，在您购
买或使⽤我们的产品和服务时也⼀并授权于我们，以便我们有合法的权利向您提供产品及服务。
本政策介绍了ByberPay向您提供ByberPay产品和/或服务中收集、接收、使⽤、处理、共享、转
让、公开和保存您和您的⽤户的个⼈信息的⽬的、⽅式、范围和信息安全保护措施，以及我们为您提供访问、更
新、删除等控制您和您的⽤户个⼈信息的权利和⽅法。为了便于您阅读及理解，我们⽤尽可能简单易懂的语句进
⾏表述，并对关键术语进⾏了定义，请参⻅本政策第⼗⼀条"名词解释"。
提醒您注意，我们提供的某些特定产品和/或服务拥有特定的隐私权保护声明。特定的隐私权保护声明更加具体
地说明了针对特定类型产品和/或服务我们是如何处理您个⼈信息的，并且特定的隐私权保护声明构成本政策的
有效组成部分。如特定的隐私权保护声明与本政策有不⼀致之处，以该特定的隐私权保护声明为准。特定的隐私
权保护声明未提及的事项，以本政策约定为准。
您知晓并确认，您在注册时已经阅读并且充分理解本政策的所有内容。在您向我们提供任何个⼈敏感信息前，请
您确认该等提供是恰当的，并且同意将您提供的个⼈敏感信息让我们按本政策所述的⽬的和⽅式进⾏处理。我们
会在得到您的同意后收集和使⽤您的个⼈敏感信息，以实现我们为您提供的相关功能。我们允许您对这些个⼈敏
感信息的收集与使⽤做出“不同意”的选择，但是拒绝提供这些信息会导致您⽆法使⽤ByberPay的产品和
服务。
请您确保，您是18周岁（含）以上⼈⼠。如您是18周岁以下的未成年⼈，应由您的⽗⺟或其他法定监护⼈陪同阅
读本政策，并经由⽗⺟或其他法定监护⼈同意本政策后，您才能使⽤ByberPay产品和服务并向我们提供您
的个⼈信息。
我们会不时更新本隐私政策，并⿎励您定期查看本隐私政策。我们将在此⻚⾯上发布任何隐私政策更改。本隐私
政策中未另⾏定义的术语将具有ByberPay客户服务条款中规定的含义。
本政策将帮助您了解以下内容：
● 1.我们收集关于您的哪些信息；
● 2.我们如何使⽤Cookie和同类技术；
● 3.我们如何以及为何使⽤您的个⼈数据；
● 4.我们如何委托第三⽅处理、共享、转让、公开披露您的个⼈信息；
● 5.我们如何保存您的个⼈信息；
● 6.我们如何保护您的个⼈信息；
● 7.我们如何使您拥有管理个⼈信息的权利；
● 8.我们如何处理未成年⼈的个⼈信息；
● 9.我们如何更新与修订本政策；
● 10.如何联系我们；
● 11.名词解释；
1. 我们收集关于您的哪些信息
我们会依据相关法律法规，遵循正当、合法、必要的原则，出于本政策所述的以下⽬的，收集和使⽤您在使⽤
ByberPay产品和服务以及您的⽤户在访问您的产品和服务中主动提供的、我们主动采集的您在使⽤
ByberPay产品和服务产⽣的、您的⽤户在访问您的产品和服务产⽣的以及我们从第三⽅获取的您的和您的
⽤户的个⼈信息。⽆论通过上述何种⽅式收集和使⽤您的和您的⽤户的个⼈信息，我们通常只会在征得您同意的
情况下进⾏，除⾮在某些情况下，基于法律义务或者可能需要保护您或其他⼈的重要利益向您和您的⽤户收集个
⼈信息。
请您理解，由于商业策略的变化，ByberPay产品和服务的功能也可能进⾏更迭和发展。如果我们要将您的
个⼈信息⽤于本政策未载明的其他⽤途，或基于特定⽬的收集您的个⼈信息将⽤于其他⽬的的，我们保证仅基于
服务⽬的⽽采集或处理您和您的⽤户的数据，并且我们绝不会⼈为地、主观地收集和处理您和您的⽤户的数据。
（⼀） 向您提供产品和服务过程中您向我们提供的您的个⼈信息
提示您注意：
如果您提供的是他⼈的个⼈信息，请您确保已取得相关主体的授权。以下将详细列出各类基本业务功能及为实现
该功能所需收集的个⼈信息，请您仔细阅读并谨慎判断是否提供您的个⼈信息以便我们为您提供相应服务。若您
拒绝提供，则⽆法全部或部分使⽤ByberPay产品和服务。
● 收集信息的场景
1.1 注册/登录账号
1.1.1 当您注册ByberPay账号时，为了帮您完成注册成为ByberPay客户，以便为您提供客户服务或
您⾃由体验ByberPay官⽅⽹站的Demo、查看资料，您需要向我们提供您的⼿机号码或者邮箱，我们会向
您的⼿机号码、邮箱发送验证码以帮助您完成账号注册。如果您仅浏览ByberPay官⽅⽹站，您不需要注册
成为ByberPay的客户及提供上述信息。
1.1.2 当您使⽤ByberPay账号并登录时，您需要输⼊邮箱，通过提供邮箱+密码的⽅式登录系统。
1.2 个⼈资料
当您成为ByberPay的注册⽤户后，您可以使⽤我们为您提供的相关ByberPay产品和服务，如果您
同意补充完善您的个⼈资料（如联系⼈姓名、电⼦邮箱、⼿机号码、公司信息，包括公司名称、您在公司中的⻆
⾊），ByberPay将收集该信息，以便能为您提供更符合您需求的产品和服务。
1.3 展示和推送产品或服务
ByberPay向您提供个性化的电⼦邮箱推送服务，如果您不想接受ByberPay给您发送的商业⼴告，
可通过提示回复退订或ByberPay提供的其他⽅式进⾏退订。
1.4 通信信息
为便于向您提供ByberPay的产品和服务，ByberPay会记录您提供的信息，例如您提供的联系⼈姓
名、⼿机号码、电⼦邮箱等。您与ByberPay联系时，ByberPay可能会保存您的通信/通话记录和内
容或您留下的联系⽅式等信息，以便与您联系或帮助您解决问题，或记录相关问题的处理⽅案及结果。
1.5 其他⽤途
ByberPay将信息⽤于本政策未载明的其他⽤途，或者将基于特定⽬的收集⽽来的信息⽤于其他⽬的时，会
事先征求您的同意。
（⼆）向您提供产品和服务中我们主动收集和使⽤的个⼈信息
为了满⾜法律法规及提供产品和服务的基本要求，保障您的个⼈信息安全与系统运⾏安全，更准确地预防钓⻥⽹
站欺诈，进⾏⻛险控制以及给您提供更好的服务体验，我们会收集您在使⽤ByberPay产品和服务过程中产
⽣的相关信息，以判断您的账户⻛险，保障我们为您正常提供服务，便于分析我们的系统问题、统计流量、排查
异常信息。这些信息也会帮助我们监测所提供服务的稳定性，如∶统计崩溃和卡顿情况，检查各项接⼝性能等。
这些信息和权限包括∶
● 设备信息
当您使⽤ByberPay产品和服务时，为了向您提供更好的产品和服务并改善⽤户体验，我们会收集设备属性
信息【（操作系统类型和版本号、制造商和型号、屏幕分辨率、硬件序列号、唯⼀设备标识符、国际移动设备身
份码IMEI、⽹络设备硬件MAC地址、软件列表、IMSI信息、⼴告标识符IDFA，设备连接信息（浏览器类型、运
营商、使⽤的语⾔）以及设备状态信息（设备传感器数据，设备应⽤安装列表）】。对于从您各种设备上收集到
的信息，我们可能会将它们进⾏关联，以便我们能在这些设备上为您提供⼀致的服务。
● ⽇志信息
服务的⽇期、时间、时⻓。
● 位置信息
当您使⽤ByberPay产品和服务时，如果您同意开启设备的定位权限并使⽤我们基于位置提供的相关服务
时，我们可能会收集和处理您实际所在位置的信息，以⽅便您不需要⼿动输⼊⾃身地理坐标就可获得相关服务。
我们会使⽤各种技术进⾏定位，获得您的IP 地址、GPS 以及通过传感器（⽐如您附近设备、Wi-Fi接⼊点和基
站）获取的信息。您可以通过关闭定位权限，从⽽停⽌收集您地理位置信息，但您可能⽆法获得相关服务或功
能，或者⽆法达到相关服务拟达到的效果。
● 唯⼀应⽤程序编号
当您使⽤ByberPay产品和服务时，某些产品和服务包含唯⼀应⽤程序编号，当您安装、激活、更新、卸载
相关产品和服务或当这些产品和服务定期与我们通信（例Demo的更新）时，系统会将此编号以及与使⽤相关的
信息（操作系统类型和应⽤程序版本号）发送给我们。
● 浏览记录
当您浏览ByberPay官⽅⽹站时，我们会收集您的浏览信息，包括浏览器类型、浏览记录和点击记录，收集
这类信息是为了改善功能，为您提供更好的体验和服务。
● ⽀付相关信息
● ⽀付记录；
● 账单信息；
● 其他与付款相关的信息；
● 服务数据
● 您在浏览ByberPay服务之前访问的⽹站；
● 您在⻚⾯或屏幕上花费了多⻓时间；
● ⻚⾯或屏幕之间的导航路径；
● 活动状态（包括第⼀次看到、最后⼀次看到、最后⼀次听到、和最后⼀次联系）；
● 会议⽇期和时间；
● 您如何与我们的电⼦邮件互动；
● ⻚⾯或屏幕之间的导航路径；
● ⽇期和时间；
● 浏览的⻚⾯；
● 链接点击；
● 语⾔偏好；
● 在客户帐户中应⽤的标签；
● ByberPay分配的⽤户标识符；
（三）我们从第三⽅获取您的个⼈信息
我们可能会在您的授权同意范围内从第三⽅（包括我们的合作⽅）处收集并使⽤您的个⼈信息。我们保证严格依
照与第三⽅签订的合同以及相关法律规定处理您的个⼈信息，同时请您详细阅读该第三⽅的隐私政策及⽤户协
议。如您拒绝第三⽅在提供服务时收集、使⽤或者传递您的个⼈信息，将可能导致您⽆法使⽤ByberPay相
应的产品和服务。涉及到的第三⽅源数据包括：
● 从社交⽹站收集的个⼈资料信息；
● 您查看或与我们的内容互动的信息；
● 公司信息；
● 电⼦邮件地址；
● 电话号码；
● 地址；
● 地理位置数据；
该第三⽅个⼈数据的来源可能包括：
● 客户⽣成提供商；
● 有针对性的在线⼴告提供商；
● 我们的身份解析和洞察管理提供商；
● 我们的地理位置 IP 信息提供商。
（四）基于征得同意的例外
根据相关法律法规的规定，在以下情形中，我们可以在不征得您的授权同意的情况下收集、使⽤⼀些必要的个⼈
信息∶
● 与履⾏法律法规规定的义务相关的；
● 与国家安全、国防安全直接相关的；
● 与公共安全、公共卫⽣、重⼤公共利益直接相关的；
● 与犯罪侦查、起诉、审判和判决执⾏等直接相关的；
● 出于维护您或其他个⼈的⽣命、财产等重⼤合法权益但⼜很难得到本⼈同意的;
● 所涉及的个⼈信息是您⾃⾏向社会公众公开的;
● 根据您的要求签订和履⾏合同所必需的;
● 从合法公开披露的信息中收集到您的个⼈信息，如从合法的新闻报道、政府信息公开等渠道;
● 法律法规规定的其他情形。
2. 我们如何使⽤Cookie和同类技术
当您通过互联⽹包括但不限于移动终端各类设备使⽤ByberPay产品和服务时，为确保⽹站正常运转，我们
会在您的设备上保存名为Cookie的⼩数据⽂件。Cookie通常包含DiviceID、站点名称以及⼀些号码和字符。
Cookie主要的功能是便于您使⽤ByberPay产品和服务，以及帮助⽹站统计独⽴访客数量等。运⽤Cookie
技术，我们能够为您提供更加周到的个性化服务，并允许您设定您特定的服务选项。
当您使⽤ByberPay产品和服务时，我们会向您的设备发送Cookie。当您与我们提供给第三⽅的服务（例
如⼴告和/或推⼴服务，以及可能显示在其他⽹站上的由ByberPay提供的服务功能）进⾏交互时，我们允
许Cookie（或者其他匿名标识符）发送给我们的服务器。
我们不会将Cookie⽤于本政策所述⽬的之外的任何⽤途。您可根据⾃⼰的偏好管理并清除计算机和/或移动设备
上保存的所有Cookie，但如果您这么做，则需要在每⼀次访问我们的⽹站时亲⾃更改⽤户设置，同时您可能因
为该等修改，⽆法登录或使⽤依赖于Cookie的ByberPay提供的服务或功能。
除Cookie外，ByberPay还会在⽹站上使⽤⽹站信标和像素标签等其他同类技术。例如，ByberPay
向您发送的电⼦邮件可能含有跳转⾄ByberPay⽹站内容的地址链接，如果您点击该链接，
ByberPay则会跟踪此次点击，帮助ByberPay了解您的产品和服务偏好，以便于ByberPay主动改善⽤户
服务体验。如果您不希望⾃⼰的活动以这种⽅式被追踪，则可以随时从ByberPay的寄信名单中退订或者
ByberPay提供的其他⽅式退订。
ByberPay Messenger 服务不会在不属于ByberPay 和您授权的的多个⽹站或应⽤程序中收集、保
留或共享有关特定⽤户活动的数据。ByberPay 确实在单个⽹站的范围内为每个⽤户分配了⼀个唯⼀的⽤
户 ID，但这个⽤户 ID的主要功能是便于您使⽤ByberPay产品和服务，不会被⽤于本政策所述⽬的之外的
任何其他⽤途。
某些 Internet 浏览器可能配置为向您访问的在线服务发送“请勿跟踪”信号。我们⽬前不回应“请勿追踪”或类似
信号。要了解有关“请勿追踪”的更多信息，请访问http://www.allaboutdnt.com。
3.我们如何以及为何使⽤您的个⼈数据
我们出于以下⽬的收集和处理您的个⼈数据：
提供和促进 ByberPay 服务和⽹站的交付：我们处理您的个⼈数据以履⾏我们与您就使⽤我们的服务和⽹
站的合同，并履⾏我们在适⽤服务条款下的义务。在我们未与您签订合同的情况下，我们会根据我们的合法利益
来处理您的个⼈数据，以运营和管理ByberPay 服务和⽹站。例如，创建、管理和管理您的帐户。
与您就ByberPay 服务进⾏沟通并提供客户⽀持：我们可能会根据我们在管理ByberPay服务⽅⾯的
合法利益向您发送服务、技术和其他管理信息。例如，我们可能会向您发送有关ByberPay服务的可⽤性或
安全性的消息。我们还处理您的个⼈数据以回复您的评论和问题，并提供客户关怀和⽀持。
改进ByberPay 服务和站点：我们处理您的个⼈数据以改进和优化ByberPay服务和站点，并了解您
如何使⽤ByberPay 服务和站点，包括监控使⽤或流量模式以及分析趋势和开发新产品、服务、特性和功
能依赖于我们的合法利益。
发送营销通讯：我们处理您的个⼈数据，以便通过电⼦邮件、邮寄或短信向您发送有关我们的产品、服务和即将
发⽣的活动的营销通讯，您可能会因依赖我们的合法利益或我们征求您的同意⽽感兴趣。
管理活动注册和出席：我们处理您的个⼈数据以计划和举办您已注册或参加的活动，包括向您发送相关通信。
维护ByberPay 服务和⽹站的安全：我们处理您的个⼈数据以控制对ByberPay 服务和⽹站的未经授
权的使⽤或滥⽤，或以其他⽅式检测、调查或防⽌可能违反ByberPay 政策或适⽤法律的活动，依赖我们
的合法利益维护和促进ByberPay 站点和服务的安全和安保。
显示个性化⼴告：我们处理您的个⼈数据以向您做⼴告并提供个性化信息，包括通过在我们的⽹站和第三⽅⽹站
上投放和管理⼴告，依靠我们的合法利益来⽀持我们的营销活动和宣传我们的产品和服务，或在必要时，在您同
意的范围内。
执⾏其他合法业务⽬的：包括开发票、审计、欺诈监控和预防。
遵守法律义务：我们根据适⽤法律规定的义务，在与公共和政府当局、法院或监管机构合作或遵守时处理您的个
⼈数据，并根据法律要求或允许，保护我们的权利、财产或安全，或我们的⽤户或公众的权利、财产或安全不受
损害。
4.我们如何委托第三⽅处理、共享、转让、公开披露您的个⼈信
息
我们仅会出于合法、正当、必要、特定、明确的⽬的共享、转让、公开批露您的个⼈信息或者委托第三⽅对您的
个⼈信息进⾏处理。我们只会向第三⽅提供服务所必要的个⼈信息。我们要求我们的第三⽅⽆权共享您的个⼈信
息⽤于本政策⽬的以外的任何其他⽤途。
（⼀）委托处理
我们提供的ByberPay产品和服务中某些具体的模块或功能可能会由外部第三⽅提供。
我们会对第三⽅获取有关信息的应⽤程序接⼝（API）、软件⼯具开发包（SDK）进⾏严格的安全检测，并与第
三⽅约定严格的数据保护措施，令其按照我们的委托⽬的、服务说明、本隐私政策以及其他任何相关的保密政策
并采取安全措施来处理您的个⼈信息。
（⼆）共享
我们不会与任何公司、组织和个⼈共享您的个⼈信息，但以下情况除外：
为了向您提供更完善、优质的产品和服务，我们的某些服务将由第三⽅提供。事先获得您的明确授权或同意后，
我们可能会与第三⽅共享您的某些个⼈信息。我们仅会出于合法、正当、必要、特定、明确的⽬的共享您的个⼈
信息，并且只会共享为您提供服务所必要的个⼈信息。我们要求我们的第三⽅⽆权将共享的个⼈信息⽤于任何其
他⽤途。 第三⽅可能有其独⽴的隐私政策和⽤户协议，我们建议您阅读并遵守第三⽅的⽤户协议及隐私保护政
策。共享个⼈信息的类型与范围将在向您获取授权或同意时进⼀步说明展示。
在法律要求或允许的范围内，为了保护我们及您或社会公众的利益、财产或安全免遭损害⽽有必要提供您的个⼈
信息给第三⽅，共享个⼈信息的类型与范围将根据法律法规及相关规定的要求⽽定。
为了保护国家安全、公共安全以及您和其他集体或个⼈的重⼤合法权益⽽需要共享您的个⼈信息。
您⾃⾏公开的或者我们能从其他合法公开渠道获取到的您的个⼈信息。
提示您注意：在特定共享情况下，我们可能通过其他授权⽅式（如弹窗告知、签署授权或使⽤协议的告知条款）
等⽅式获取您的同意。任何其他授权⽅式所约定的内容或者⽂案将构成本政策的有效组成部分。如果您拒绝我们
在提供特定服务时与第三⽅共享您的个⼈信息的，将可能导致您⽆法获得和使⽤该第三⽅提供的服务。
（三）转让
我们不会将您的个⼈信息转让给除ByberPay 及其关联公司外的任何公司、组织和个⼈，但以下情形除外∶
● 已获取您的明确同意;
● 根据所适⽤的法律法规、⾏业规定、法律程序要求、诉讼/仲裁、政府的强制命令、监管要求所必须提供
的;
● 为了保护国家安全、公共安全以及您和其他集体或个⼈的重⼤合法权益⽽需要转让您的个⼈信息;
● 您⾃⾏公开的或者我们能从其他合法公开渠道获取到您的个⼈信息。
● 如果发⽣合并、收购、分离或破产清算，将可能涉及到个⼈信息转让，我们将确保该等信息在转移时的机
密性，此种情况下我们会要求新的持有您个⼈信息的公司、组织继续受本政策的约束。如果本政策中约定的
个⼈信息的收集、处理⽅式发⽣任何改变，该公司、组织将重新向您征求授权同意。
（四）公开披露
除⾮获取您的明确同意，我们不会公开披露您的个⼈信息。
基于法律法规、法律程序、诉讼/仲裁或政府主管部⻔强制性要求的情况下，我们可能会向有权机关披露您的个
⼈信息。但我们保证，在上述情况发⽣时，我们会要求披露请求⽅必须出具与之相应的有效法律⽂件，并对被披
露的信息采取符合法律和业界标准的安全防护措施。
5.我们如何保存您的个⼈信息
您的个⼈信息安全对于我们⾄关重要。我们将严格遵守相关法律法规，采取业内认可的合理可⾏的措施，保护您
的个⼈信息。防⽌信息遭到未经授权的访问、披露、使⽤、修改，避免信息损坏或丢失。
保存期限
您在使⽤我们的产品和服务期间，我们将持续为您保存您的个⼈信息，保存期限以为实现本政策下业务所需的最
短时间为准。如果您注销账户或主动删除上述信息，在您注销账户或主动删除上述信息后，我们不会再对您的个
⼈信息进⾏商业化使⽤，但我们可能会对您的个⼈信息进⾏匿名化处理后使⽤。
6.我们如何保护您的个⼈信息
（⼀）技术措施与数据安全措施
我们努⼒采取各种符合业界标准的物理、电⼦和管理⽅⾯的安全措施来保护您的个⼈信息安全，如∶SSL/应⽤层
加密技术、HTTPS双向认证技术、数字签名等，以防⽌个⼈信息在收集、保存、传输等过程中遭到泄露、破
坏，并通过⽤户协议和隐私政策告知您相关个⼈信息的使⽤⽬的和范围。
我们积极建⽴数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范个⼈信息的保存和使⽤，确
保不收集与我们提供的服务⽆关的个⼈信息。
我们会对员⼯进⾏数据安全的意识培养和安全能⼒的培训和考核，加强员⼯对于个⼈信息保护重要性的认识。我
们会部署访问控制机制，对处理个⼈信息的员⼯进⾏身份认证及权限控制。我们会与信息接触者、第三⽅明确访
问、公开披露、使⽤、修改的权限及范围，以防⽌个⼈信息损坏或丢失。
我们会定期进⾏数据安全能⼒评估/信息安全⻛险评估，确保安全保护系统始终处于完备状态。
我们成⽴了专职团队负责研发和应⽤多种安全技术和程序等，我们会对安全管理负责⼈和关键安全岗位的⼈员进
⾏安全背景审查，我们建⽴了完善的信息安全管理制度和内部安全事件处置机制等。我们会采取符合业界标准的
合理可⾏的安全措施和技术⼿段保存和保护您的个⼈信息，以防⽌您的信息丢失、遭到被未经授权的访问、公开
披露、使⽤、修改、毁损、丢失或泄漏。我们会采取⼀切合理可⾏的措施保护您的个⼈信息。我们会使⽤加密技
术确保数据的保密性。我们会使⽤受信赖的保护机制防⽌数据遭到恶意攻击。为了更好的防⽌您的信息泄露，请
您使⽤复杂密码，协助我们保证您的账户以及个⼈信息安全。
（⼆）安全事件处置
我们将尽⼒确保您发送给我们的任何信息的安全性，但请您理解，由于技术的限制以及在互联⽹⾏业可能存在的
各种恶意⼿段，不可能始终保证信息百分之百的安全。您需要了解您接⼊我们服务所⽤的系统和通讯⽹络有可能
因我们控制范围外的因素⽽出现问题，因此，在发⽣前述安全问题时，您表示充分理解，⽽我们会尽全⼒将损害
降⾄最低。
为防⽌安全事故的发⽣，我们会制定⽹络安全事件应急预案，及时处置系统漏洞、计算机病毒、⽹络攻击、⽹络
侵⼊等安全问题，在发⽣危害⽹络安全的事件时，我们会⽴即启动应急预案，采取相应的补救措施，⼒求将损失
最⼩化，并按照规定向有关主管部⻔报告。
个⼈信息泄露、毁损、丢失属于公司特⼤级别安全事件，我们会负责定期组织⼯作组成员进⾏安全预案演练，防
⽌此类安全事件发⽣。若⼀旦不幸发⽣，我们将按照最⾼优先级启动应急预案，组成应急响应⼩组，在最短时间
内追溯原因并减少损失。
在不幸发⽣个⼈信息安全事件后，我们将按照法律法规的要求，及时向您告知安全事件的基本情况和可能的影
响、我们已采取或将要采取的处理措施、我们向您告知的您可⾃主防范和降低⻛险的建议，以及对您的补救措施
等。我们会及时将事件相关情况以站内通知、短信通知、电话、邮件等您预留的联系⽅式告知您，难以逐⼀告知
时，我们会采取合理、有效的⽅式发布公告。
同时，我们还将按照监管部⻔要求，主动上报个⼈信息安全事件的处置情况，密切配合政府机关的⼯作。
7.我们如何使您拥有管理个⼈信息的权利
我们⾮常重视您对个⼈信息享有的权利，并尽全⼒保护您对于⾃身信息访问、更正、注销账户的权利，以便您拥
有充分的能⼒保障您的隐私和安全。
您的权利包括：
（⼀）访问、更正权
您有权访问和更正您的个⼈信息，您可以通过官⽹显示⽅式联系客服⼈员对您的个⼈信息进⾏访问。
（⼆）删除权
在以下情形中，您可以向我们提出删除您的个⼈信息的请求，我们会响应您的删除请求。您可以通过
ByberPay 产品和服务提供的联系⽅式随时与我们联系：
● 如果我们违反法律法规或与您的约定收集、使⽤个⼈信息的;
● 如果我们违反法律法规或与您的约定与第三⽅共享或转让您的个⼈信息，我们将⽴即停⽌共享、转让⾏
为，并通知第三⽅及时删除;
● 如果我们违反法律法规规定或与您的约定，公开披露您的个⼈信息，我们将⽴即停⽌公开披露的⾏为，并
发布通知要求相关接收⽅删除相应的信息。您可以联系官⽹显示⽅式联系客服⼈员要求删除您的个⼈信息。
以上删除请求⼀旦被响应，除法律法规另有规定要求保留的信息外，您的个⼈信息将被及时删除。
（三）撤回同意权
每个业务功能往往需要收集⼀些基本的个⼈信息才能得以运营，如您想改变授权范围，您可以通过官⽹显示⽅式
联系客服⼈员要求撤回同意并要求删除您的个⼈信息。
当您撤回同意或授权后，我们⽆法继续为您提供撤回同意或授权所对应的服务，也将不再处理您相应的个⼈信
息。但您撤回同意或授权的决定，不会影响此前基于您的同意或授权⽽开展的个⼈信息处理活动。
（四）注销ByberPay服务账号
如果您想注销已注册的ByberPay 账号，您可以通过联系客服注销。注销账号后，您将⽆法继续享有
ByberPay为您提供的服务。
（五）获取个⼈信息副本权
根据法律法规及相关国家标准，您有权获取您的个⼈基本资料、个⼈身份信息副本，您可以通过官⽹显示⽅式联
系我们。
（六）拒绝定向推送
如果您不想收到通过算法⾃动决策后的个性化⼴告推送的，您可以通过官⽹所述⽅式联系我们。您拒绝接受定向
推送服务之后，我们将会对您的此部分个⼈信息进⾏删除或作匿名化处理，并且向您提供业务功能时，不再基于
您的个⼈信息做定向推送。
（七）响应您的上述请求
为保障安全，我们可能会要求先验证您的身份，再处理您的请求。您可能需要提供书⾯请求，或以其他⽅式证明
您的身份。对于您提出的本条项下的所有请求，经过收到您的请求并在验证您⽤户身份后，我们原则上会在⼗五
⽇内进⾏反馈。对于您合理的请求，我们原则上将积极响应并不收取费⽤，但对于那些⽆端重复、需要过多技术
⼿段（例如，需要开发新系统或从根本上改变现⾏惯例）、给他⼈合法权益带来⻛险或者⾮常不切实际的请求，
我们可能会予以拒绝。在以下情形中，按照法律法规要求，我们将⽆法响应您的上述请求：
● 与我们履⾏法律法规规定的义务相关的;
● 与国家安全、国防安全直接相关的;
● 与公共安全、公共卫⽣、重⼤公共利益直接相关的;
● 与犯罪侦查、起诉、审判和执⾏判决等直接相关的;
● 我们有充分证据表明您存在主观恶意或滥⽤权利的（如您的请求将危害公共安全和其他集体或个⼈的合法
权益，或您的请求超出了⼀般技术⼿段和商业成本可覆盖的范围）;
● 出于维护个⼈信息主体或其他个⼈的⽣命、财产等重⼤合法权益但⼜很难得到本⼈同意的;
● 响应个⼈信息主体的请求将导致您或其他个⼈、组织的合法权益受到严重损害的;
● 涉及商业秘密的;
● 法律法规规定的其他情形。
8.我们如何处理未成年⼈的个⼈信息
我们⾮常重视对未成年⼈信息的保护。ByberPay产品和服务主要⾯向企业和成⼈，在使⽤
ByberPay产品和服务前，请您确保您是18周岁（含）以上⼈⼠。如您是18周岁以下的未成年⼈，您应确保您是在⽗
⺟或其他法定监护⼈陪同阅读并同意本政策后，您才能使⽤ByberPay产品和服务并向我们提供您的个⼈信
息。
对于经⽗⺟或其他法定监护⼈同意⽽收集未成年⼈个⼈信息的情况，我们只会在受到法律允许、⽗⺟或其他法定
监护⼈明确同意或者保护未成年⼈所必要的情况下使⽤或公开披露此信息。
若您是未成年⼈的⽗⺟或其他法定监护⼈，当您对您所监护的未成年⼈使⽤我们的产品和服务或其向我们提供的
⽤户信息有任何疑问时，请您及时与我们联系。我们将根据相关法律法规及本政策的规定保护未成年⼈⽤户信息
的保密性及安全性。如果我们发现⾃⼰在未事先获得可证实的⽗⺟或其他法定监护⼈同意的情况下收集了未成年
⼈的个⼈信息，则会尽快删除相关数据。
9.我们如何更新与修订本政策
我们保留适时更新本政策的权利。未经您明确同意，我们不会改变您按照本政策所应享有的权利。如隐私政策发
⽣更新，我们会通过合理⽅式（包括弹窗提示、在⻚⾯显著位置提示、向您发送电⼦邮件或以其他⽅式）通知
您，以便您能及时了解本政策所做的任何变更。
对于重⼤变更，视具体情况我们可能还会提供更为显著的通知，说明本政策具体变更的内容。重⼤变更包括但不
限于：
（⼀）我们的服务模式和业务形态发⽣重⼤变化。如处理个⼈信息的⽬的、处理的个⼈信息类型、个⼈信息的使
⽤⽅式等;
（⼆）个⼈信息共享、转让或公开披露的主要对象发⽣变化;
（三）您参与个⼈信息处理⽅⾯的权利及其⾏使⽅式发⽣重⼤变化;
若您不同意修改后的隐私政策，您有权并应⽴即停⽌使⽤ByberPay产品和服务。如果您继续使⽤
ByberPay 产品和服务，则视为您接受我们对本政策相关条款所做的修改。
10.如何联系我们
如您对本政策有任何意⻅或建议，甚⾄因本政策产⽣任何争议的，可通过官⽹显示⽅式联系ByberPay的隐
私保护机构，我们将安排专员及时为您提供咨询或协调解决您投诉、申诉的问题。⼀般情况下，我们将在⼗五⽇
内回复。为保障我们⾼效处理您的问题并及时向您反馈，您需要向我们提交有效身份证明、有效联系⽅式和书⾯
请求及相关证据，我们会在验证您的身份后处理您的请求。
11.名词解释
本政策中使⽤的特定词语，具有如下含义∶
控制：是指直接或间接地拥有影响所提及公司管理的能⼒，⽆论是通过所有权、有投票权的股份、合同或其他被
依法认定的⽅式。
您：指使⽤我们的产品和服务的项⽬⽅、项⽬⽅的客户以及其他第三⽅。
第三⽅：是指ByberPay与第三⽅建⽴合作关系的供应商。该供应商负责提供ByberPay产品和服务
中某些具体的模块或功能或服务。
个⼈信息：指以电⼦或者其他⽅式记录的能够单独或者与其他信息结合识别特定⾃然⼈身份或者反映特定⾃然⼈
活动情况的各种信息。个⼈信息包括个⼈基本信息、个⼈身份信息、个⼈⽣物识别信息、⽹络身份标识信息、个
⼈健康⽣理信息、个⼈教育⼯作信息、个⼈财产信息、个⼈通信信息、联系⼈信息、个⼈上⽹记录、个⼈常⽤设
备信息、个⼈位置信息等。为免疑义，个⼈信息包括但不限于个⼈敏感信息。
个⼈信息主体：指个⼈信息所标识的⾃然⼈。
个⼈敏感信息：指⼀旦泄露、⾮法提供或滥⽤可能危害⼈身和财产安全，极易导致个⼈名誉、身⼼健康受到损害
或歧视性待遇等的个⼈信息。个⼈敏感信息包括个⼈财产信息、个⼈健康⽣理信息、个⼈⽣物识别信息、个⼈身
份信息、⽹络身份标识信息等。
去标识化：指通过对个⼈信息的技术处理，使其在不借助额外信息的情况下，⽆法识别个⼈信息主体的过程。
匿名化：指通过对个⼈信息的技术处理，使得个⼈信息主体⽆法被识别，且处理后的信息不能被复原的过程。
设备：指可⽤于访问ByberPay产品和服务的装置，例如智能⼿机等。
设备标识符：指由设备制造商编⼊到设备中的⼀串字符，可⽤于以独有⽅式标识相应设备（例如⼿机的SN
号）。
IP地址：每台上⽹的设备都会指定⼀个编号，称为互联⽹协议（IP）地址，这些编号通常都是根据地理区域指定
的。

`,
      text2: `
      
Effective Date: July 1, 2024

ByberPay attaches great importance to the protection of users' personal information and privacy. Therefore, ByberPay has formulated the Privacy Policy covering how to collect, receive, use, process, share, transfer, disclose, preserve and protect user information (hereinafter referred to as "This Policy/This Privacy Policy").

This policy is closely related to our service you are using, once you visit our web site, use, or continue to use our services, or provide your information to us, that means that you agree to our in accordance with the "privacy policy" collection, use, processing, sharing, transfer, open and save your personal information. We hope that before you browse our website or accept our services, you can carefully read all the terms of this Privacy Policy, confirm that you have fully understood our rules on handling your personal information, and make the choice you think is appropriate. Please note that if you do not agree to be bound by the content of this Privacy Policy, you should not use our website or services.

The term "you" in this policy also includes your users in a specific context, that is, the personal information provided by your users to you through access, telephone connection, etc., is also authorized to us when you purchase or use our products and services, so that we have the legal right to provide products and services to you.

This policy describes the purpose, method, scope and information security protection measures of personal information collected, received, used, processed, shared, transferred, disclosed and stored by ByberPay to provide you with ByberPay products and/or services, And we provide you with the rights and methods to access, update, delete, etc. to control you and your users' personal information. In order to facilitate your reading and understanding, we have expressed it in as simple and easy-to-understand terms as possible, and defined key terms. Please refer to Article 11 "Terms Explanation" of this policy.

We remind you that certain products and/or services we offer carry specific privacy protection statements. Specific privacy statements more specifically describe how we handle your personal information for specific types of products and/or services, and specific privacy statements form an effective part of this Policy. In the event of any inconsistency between a particular privacy statement and this Policy, that particular privacy Statement shall prevail. For matters not mentioned in the specific privacy protection statement, this policy shall prevail.

You acknowledge and confirm that you have read and fully understood all of this policy when you register. Before you provide us with any sensitive personal information, please confirm that such provision is appropriate and agree to allow us to process the sensitive personal information you provide for the purposes and methods described in this policy. We will collect and use your personal sensitive information after obtaining your consent to realize the relevant functions we provide you. We allow you to choose not to consent to the collection and use of such personal sensitive information, but refusing to provide this information will prevent you from using ByberPay's products and services.

Please make sure that you are over 18 years old (inclusive). If you are a minor under the age of 18, you should be accompanied by your parents or other legal guardians to read this policy, and after the parents or other legal guardians agree to this policy, you can use ByberPay products and services and provide us with your personal information.

We will update this Privacy Policy from time to time and encourage you to review this Privacy Policy regularly. We will post any privacy policy changes on this page. 

This policy will help you understand the following:

● 1. What information we collect about you;
● 2. How we use cookies and similar technologies;
● 3. How and why we use your personal data;
● 4. How we entrust a third party to process, share, transfer and publicly disclose your personal information;
● 5. How we store your personal information;
● 6. How we protect your personal information;
● 7. How we empower you to manage your personal information;
● 8. How we handle personal information of minors;
● 9. How we update and revise this policy;
● 10. How to contact us;
● 11. Explanation of terms;

1.What information we collect about you
We will, in accordance with the relevant laws and regulations, follow the principles of legitimacy, legality and necessity, and for the following purposes described in this policy, collect and use your use of ByberPay products and services and your users' access to your Your and your users’ personal information that is actively provided in products and services, that we actively collect, that are generated when you use ByberPay products and services, that are generated when your users visit your products and services, and that we obtain from third parties . Regardless of the ways in which your and your users' personal information is collected and used above, we will generally only do so with your consent, except in certain circumstances, based on legal obligations or may be necessary to protect you or others important interests of people to collect personal information from you and your users.

Please understand that due to changes in business strategies, the functions of ByberPay products and services may also be changed and developed. If we want to use your personal information for other purposes not specified in this policy, or collect your personal information for a specific purpose and use it for other purposes, we guarantee that we will only collect or process you and your users for the purpose of service , and we will never artificially and subjectively collect and process your and your users' data.

（一）Your personal information provided to us in the process of providing products and services to you
You are reminded that:

If you provide other people's personal information, please ensure that you have obtained the authorization of the relevant subject. The following will list in detail various basic business functions and the personal information collected for the realization of this function. Please read carefully and carefully judge whether to provide your personal information so that we can provide you with corresponding services. If you refuse to provide, you will not be able to use ByberPay products and services in whole or in part.

● Information collection scenario

1.1 Register/Login account
1.1.1 When you register your ByberPay account, you need to provide us with your mobile phone number or email address in order to help you complete the registration as a ByberPay customer and provide you with customer services or experience the Demo and view materials on the official website of ByberPay freely. We will send a verification code to your mobile phone number and email address to help you complete account registration. If you only visit ByberPay's official website, you do not need to register as a ByberPay customer and provide the above information.

1.1.2 When you log in to the system using the ByberPay account, you need to enter your email address. You can log in to the system using email address + password.

1.2 The personal data
After you become a registered user of ByberPay, you may use the relevant ByberPay products and services provided by us. If you agree to supplement and improve your personal information (such as contact name, email address, mobile phone number, company information, including company name and your role in the company), ByberPay will collect this information in order to provide you with products and services that better meet your needs.

1.3 Display and push products or services
ByberPay provides personalized push email service to you. If you do not want to accept the commercial ads sent to you by ByberPay, you can unsubscribe by prompting you to reply or other means provided by ByberPay.

1.4 Communication information
In order to provide ByberPay's products and services to you, ByberPay records the information you provide, such as the contact name, mobile phone number, and email address. When you contact ByberPay, ByberPay may save your communication/call history and contents or contact information left by you, so as to contact you or help you solve problems, or record solutions and results of related problems.

1.5 Other uses
When ByberPay uses the information for other purposes not specified in this policy, or uses the information collected for a specific purpose for other purposes, it will seek your prior consent.

（二）To provide you with personal information that we actively collect and use in our products and services

In order to meet the basic requirements of laws and regulations and provide products and services, protect your personal information and system operation security, more accurately prevent phishing website fraud, carry out risk control and provide you with a better service experience, we will collect your information on Use the relevant information generated during the use of ByberPay products and services to judge your account risk, ensure that we provide you with normal services, and facilitate analysis of our system problems, statistics of traffic, and troubleshooting of abnormal information. This information will also help us monitor the stability of the services we provide, such as: counting crashes and freezes, checking the performance of various interfaces, etc.

These information and permissions include:

● Device Information
When you use ByberPay products and services, in order to provide you with better products and services and improve user experience, we will collect device attribute information [(operating system type and version number, manufacturer and model, screen resolution, hardware serial ID number, unique device identifier, IMEI, network device hardware MAC address, software list, IMSI information, advertising identifier IDFA, device connection information (browser type, carrier, language used), and device status Information (device sensor data, device application installation list))]. For the information collected from your various devices, we may associate them so that we can provide you with consistent services on these devices.

● Log Information
The date, time, and duration of the service.

● Location Information
When you use ByberPay products and services, if you agree to enable the location permission of your device and use our location-based related services, we may collect and process your actual location information so that you do not need to manually enter your own geographic location Coordinate to get related services. We use a variety of technologies to locate, obtain your IP address, GPS, and information from sensors such as devices near you, Wi-Fi access points, and cell towers. You can stop the collection of your geographic location information by turning off the location permission, but you may not be able to obtain related services or functions, or you may not be able to achieve the intended effects of related services.

● Unique Application Number
When you use ByberPay products and services, some products and services contain unique application numbers, when you install, activate, update, uninstall related products and services or when these products and services communicate with us periodically (such as Demo updates) , this number is sent to us along with usage-related information (OS type and application version number).

● Browsing History
When you browse the official website of ByberPay, we will collect your browsing information, including browser type, browsing history and click history. The purpose of collecting this information is to improve the function and provide you with a better experience and service.

● Payment Related Information
payment record；
billing information；
other payment-related information；

● Service Data
the website you visited prior to browsing the ByberPay service;
how long you spend on the page or screen;
navigation paths between pages or screens;
activity status (including first seen, last seen, last heard, and last contacted);
the date and time of the meeting;
how you interact with our emails;
navigation paths between pages or screens;
date and time;
pages viewed;
link click;
language preference;
labels applied in the client's account;
user identifier assigned by ByberPay;

（三）We obtain your personal information from third parties
We may collect and use your personal information from third parties (including our partners) within the scope of your authorization and consent. We guarantee to process your personal information in strict accordance with the contracts signed with third parties and relevant laws and regulations, and please read the third party's privacy policy and user agreement in detail. If you refuse the collection, use or transmission of your personal information by third parties when providing services, it may cause you to be unable to use ByberPay's corresponding products and services. The third-party source data involved include:

- profile information collected from social networking sites;
- information about your viewing or interaction with our content;
- company information;
- email address;
- phone number;
- address;
- geolocation data;

The sources of this third-party personal data may include:
- customer generation providers;
- targeted online advertising providers;
- our identity resolution and insights management provider;
- our geolocation IP information provider.

（四）Exception for content with consent
According to relevant laws and regulations, we may collect and use some necessary personal information without your authorization and consent in the following situations:

● Related to the performance of obligations stipulated by laws and regulations;
● Those directly related to national security and national defense security;
●  Directly related to public safety, public health, and major public interests;
● Directly related to criminal investigation, prosecution, trial and execution of judgments;
● In order to protect your or other personal life, property and other major legitimate rights and interests, but it is difficult to obtain my consent;
● The personal information involved is disclosed to the public by you;
● Necessary to enter into and perform a contract at your request;
● Your personal information is collected from legally publicly disclosed information, such as legal news reports, government information disclosure and other channels;
● Other situations stipulated by laws and regulations. 

2.How we use cookies and similar technologies

When you use ByberPay products and services through the Internet, including but not limited to mobile terminals, in order to ensure the normal operation of the website, we will save a small data file called a cookie on your device. Cookies usually contain DiviceID, site name, and some numbers and characters. The main function of cookies is to facilitate your use of ByberPay products and services, and to help the website count the number of unique visitors. Using cookie technology, we can provide you with more thoughtful personalized services and allow you to set your specific service options.

We send cookies to your device when you use ByberPay products and services. We allow cookies (or other anonymous identifiers) to be sent to the our server. 

We do not use cookies for any purpose other than those described in this policy. You can manage and clear all cookies saved on your computer and/or mobile device according to your preferences, but if you do so, you will need to personally change your user settings every time you visit our website, and you may, because of such changes, Inability to log in or use services or features provided by ByberPay that rely on cookies. 

In addition to cookies, ByberPay also uses other similar technologies such as web beacons and pixel tags on the website. For example, an email sent to you by ByberPay may contain an address link to the content of the ByberPay website. If you click on the link, ByberPay will track the click to help ByberPay understand your product and service preferences so that ByberPay can proactively improve User service experience. If you do not want your activity to be tracked in this way, you can unsubscribe from ByberPay's mailing list at any time or in other ways provided by ByberPay.

ByberPay Messenger Service does not collect, retain or share data about specific user activity across multiple websites or applications not owned by ByberPay and authorized by you. ByberPay does assign each user a unique user ID within the scope of a single website, but the primary function of this user ID is to facilitate your use of ByberPay products and services and will not be used for any purposes other than those described in this policy. Other uses. 

Some Internet browsers may be configured to send "Do Not Track" signals to the online services you visit. We do not currently respond to "do not track" or similar signals. To learn more about Do Not Track, please visit http://www.allaboutdnt.com. 

3.How and why we use your personal data

We collect and process your personal data for the following purposes:

To provide and facilitate the delivery of the ByberPay Services and Website: We process your Personal Data to perform our contract with you regarding the use of our Services and Website and to perform our obligations under the applicable Terms of Service. In the absence of a contract with you, we process your personal data in accordance with our legitimate interests to operate and administer the ByberPay service and website. For example, to create, manage and manage your account. 

To communicate with you about the ByberPay Services and to provide customer support: We may send you service, technical and other administrative information based on our legitimate interests in administering the ByberPay Services. For example, we may send you messages about the availability or security of the ByberPay service. We also process your personal data to respond to your comments and questions and to provide customer care and support.

Improving ByberPay Services and Sites: We process your Personal Data to improve and optimize ByberPay Services and Sites and to understand how you use ByberPay Services and Sites, including monitoring usage or traffic patterns and analyzing trends and developing new products, services, features and Functionality relies on our legitimate interests.

Sending marketing communications: We process your personal data in order to send you marketing communications about our products, services and upcoming events by email, post or text message, which you may rely on for our legitimate interests or where we solicit interested in your consent. 

Manage event registration and attendance: We process your personal data to plan and conduct events for which you have registered or attended, including sending you relevant communications. 

Maintaining the security of the ByberPay Services and Website: We process your personal data to control unauthorized use or misuse of the ByberPay Services and Website, or to otherwise detect, investigate or prevent activity that may violate ByberPay policies or applicable law, Rely on our legitimate interests to maintain and promote the safety and security of the ByberPay Site and Services.

Displaying personalised advertisements: We process your personal data to advertise to you and provide you with personalised information, including relying on our legitimate interests to support our marketing activities by placing and managing advertisements on our website and on third-party websites and to advertise our products and services, or where necessary, to the extent of your consent. 

To perform other legitimate business purposes: including invoicing, auditing, fraud monitoring and prevention. 

To comply with legal obligations: we process your personal data in accordance with our obligations under applicable law, in cooperation with or in compliance with public and governmental authorities, courts or regulators, and as required or permitted by law to protect our rights, property or safety, or the rights, property or safety of our users or the public are not compromised.

4.How we entrust a third party to process, share, transfer and publicly disclose your personal information

We will only share, transfer, publicly disclose your personal information or entrust a third party to process your personal information for legitimate, legitimate, necessary, specific and explicit purposes. We will only provide third parties with personal information necessary to provide services. We require that our third parties have no right to share your personal information for any other purpose than the purpose of this policy.

（一）Delegated processing
Some specific modules or functions in the ByberPay products and services we provide may be provided by external third parties.

We will conduct strict security testing on the application programming interface (API) and software tool development kit (SDK) of the third party to obtain relevant information, and agree with the third party on strict data protection measures, so that they can follow our entrustment Purpose, Service Description, this Privacy Policy and any other relevant confidentiality policies and security measures to handle your personal information.

（二）Shared
We will not share your personal information with any companies, organizations and individuals, with the following exceptions:
In order to provide you with better and better products and services, some of our services will be provided by third parties. We may share some of your personal information with third parties with your express prior authorization or consent. We will only share your personal information for legal, legitimate, necessary, specific, and explicit purposes, and only share personal information that is necessary to provide you with services. We require that our third parties have no right to use the shared personal information for any other purpose. Third parties may have their own independent privacy policies and user agreements. We recommend that you read and abide by the third party's user agreements and privacy protection policies. The type and scope of sharing personal information will be further explained when obtaining your authorization or consent.
To the extent required or permitted by law, it is necessary to provide your personal information to third parties in order to protect the interests, property or safety of us and you or the public from damage. The type and scope of shared personal information will be based on the law It depends on the requirements of regulations and related regulations.
It is necessary to share your personal information in order to protect national security, public safety and the significant legitimate rights and interests of you and other collectives or individuals.
Your personal information that you disclose by yourself or that we can obtain from other legitimate public channels.
Note to you: In certain sharing situations, we may obtain your consent through other authorization methods (such as pop-up notification, signing authorization or notification terms of the use agreement). The content or text agreed in any other authorization method will constitute an effective part of this policy. If you refuse to share your personal information with a third party when we provide certain services, it may result in you being unable to obtain and use the services provided by the third party.

（三）Transfer
We will not transfer your personal information to any company, organization or individual other than ByberPay and its affiliates, except in the following cases:
● Your express consent has been obtained;
● Required according to applicable laws and regulations, industry regulations, legal process requirements, litigation/arbitration, government mandatory orders, and regulatory requirements;
● It is necessary to transfer your personal information in order to protect national security, public safety and the significant legitimate rights and interests of you and other collectives or individuals;
● Your personal information disclosed by you or we can obtain your personal information from other legitimate public channels.
● In the event of a merger, acquisition, separation or bankruptcy liquidation, the transfer of personal information may be involved. We will ensure the confidentiality of such information during the transfer. In this case, we will require a new company that holds your personal information. , the organization continues to be bound by this policy. If there is any change in the collection and processing of personal information stipulated in this policy, the company or organization will seek your authorization and consent again.

（四）Public Disclosure
We will not publicly disclose your personal information without your express consent.
Based on laws and regulations, legal procedures, litigation/arbitration or mandatory requirements of government authorities, we may disclose your personal information to competent authorities. However, we guarantee that when the above situation occurs, we will require the disclosure requesting party to issue corresponding valid legal documents, and take security protection measures in line with laws and industry standards for the disclosed information.

5.How we store your personal information

The security of your personal information is of the utmost importance to us. We will strictly abide by relevant laws and regulations, and take reasonable and feasible measures recognized by the industry to protect your personal information. Protect information from unauthorized access, disclosure, use, modification, and avoid damage or loss of information.

Shelf Life
During your use of our products and services, we will continue to save your personal information for you, and the storage period is the shortest time required to realize the business under this policy. If you cancel your account or actively delete the above information, after you cancel your account or actively delete the above information, we will no longer use your personal information for commercial purposes, but we may anonymize your personal information before using it .

6.How we protect your personal information

（一）Technical measures and data security measures
We strive to take various physical, electronic and management security measures in line with industry standards to protect your personal information, such as: SSL/application layer encryption technology, HTTPS two-way authentication technology, digital signature, etc., to prevent personal information If it is leaked or destroyed in the process of collection, storage, transmission, etc., it will inform you of the purpose and scope of the use of relevant personal information through the user agreement and privacy policy.
We actively establish a data classification and grading system, data security management specifications, and data security development specifications to manage and regulate the storage and use of personal information, and ensure that personal information unrelated to the services we provide is not collected.
We will conduct data security awareness training and security capability training and assessment for employees to strengthen employees' awareness of the importance of personal information protection. We will deploy an access control mechanism to conduct identity authentication and permission control for employees who process personal information. We will clarify the authority and scope of access, public disclosure, use, and modification with information contacts and third parties to prevent damage or loss of personal information.
We will regularly conduct data security capability assessment/information security risk assessment to ensure that the security protection system is always in a complete state.
We have established a full-time team responsible for the development and application of a variety of security technologies and procedures. We will conduct security background checks on the person in charge of security management and personnel in key security positions. We have established a sound information security management system and internal security incidents. handling mechanism, etc. We will take reasonable and practicable security measures and technical means in line with industry standards to save and protect your personal information to prevent your information from being lost, accessed without authorization, publicly disclosed, used, modified, damaged, lost or otherwise leakage. We will take all reasonably practicable steps to protect your personal information. We use encryption technology to ensure the confidentiality of data. We use trusted protection mechanisms to protect data from malicious attacks. In order to better prevent your information from being leaked, please use a complex password to help us keep your account and personal information safe.

（二）Security Incident Handling
We will try our best to ensure the security of any information you send to us, but please understand that due to technical limitations and various malicious means that may exist in the Internet industry, it is impossible to always guarantee 100% security of information. You need to understand that the systems and communication networks you use to access our services may have problems due to factors beyond our control. Therefore, when the aforementioned security issues occur, you fully understand and we will do our best to minimize the damage. lowest.
In order to prevent the occurrence of security incidents, we will formulate contingency plans for network security incidents to promptly deal with security issues such as system vulnerabilities, computer viruses, network attacks, and network intrusions. , take corresponding remedial measures, strive to minimize losses, and report to the relevant competent authorities in accordance with regulations.
The leakage, damage and loss of personal information is a major security incident of the company. We will be responsible for regularly organizing members of the working group to conduct security plan drills to prevent such security incidents from occurring. In the event of an unfortunate occurrence, we will start the emergency plan according to the highest priority, and form an emergency response team to trace the cause and reduce losses in the shortest time.
In the event of an unfortunate personal information security incident, we will promptly inform you of the basic situation and possible impact of the security incident in accordance with the requirements of laws and regulations, the handling measures we have taken or will take, and the information we have informed you about your options. Advice on self-prevention and risk reduction, and remedies for you, etc. We will promptly notify you of the relevant information of the event through the contact information you have reserved, such as on-site notifications, SMS notifications, phone calls, and emails. When it is difficult to inform you one by one, we will publish announcements in a reasonable and effective way.
At the same time, we will also actively report the handling of personal information security incidents in accordance with the requirements of the regulatory authorities, and closely cooperate with the work of government agencies.

7.How we empower you to manage your personal information

We attach great importance to your rights to your personal information, and do our best to protect your rights to access, correct, and cancel your account, so that you can fully protect your privacy and security.
Your rights include:

(一) Right of access and correction
You have the right to access and correct your personal information. You can access your personal information by contacting the customer service staff through the display on the official website.

(二) Right of deletion
In the following circumstances, you may request us to delete your personal information, and we will respond to your deletion request. You can contact us at any time through the contact details provided by ByberPay products and services:
● If we collect and use personal information in violation of laws and regulations or our agreement with you;
● If we share or transfer your personal information with a third party in violation of laws and regulations or our agreement with you, we will immediately stop the sharing and transfer, and notify the third party to delete it in time;
● If we publicly disclose your personal information in violation of laws and regulations or our agreement with you, we will immediately stop the public disclosure and issue a notice to require relevant recipients to delete the corresponding information. You can contact customer service staff to request deletion of your personal information by contacting the official website.
Once the above deletion request is responded to, your personal information will be deleted in a timely manner, except for the information required to be retained as otherwise stipulated by laws and regulations.

(三) Right to withdraw consent
Each business function often needs to collect some basic personal information to operate. If you want to change the scope of authorization, you can contact the customer service staff through the official website display method to request to withdraw consent and request to delete your personal information.
When you withdraw your consent or authorization, we cannot continue to provide you with the services corresponding to the withdrawal of your consent or authorization, and we will no longer process your corresponding personal information. However, your decision to withdraw your consent or authorization will not affect your previous personal information processing activities based on your consent or authorization.

(四) Cancellation of ByberPay service account
If you want to cancel the registered ByberPay account, you can cancel it by contacting customer service. After canceling your account, you will no longer be able to enjoy the services provided by ByberPay.

(五) The right to obtain copies of personal information
According to laws and regulations and relevant national standards, you have the right to obtain copies of your basic personal data and personal identity information. You can contact us through the display method on the official website.

(六) Rejection of targeted push
If you do not want to receive personalized advertisements that are automatically determined by algorithms, you can contact us through the methods described on the official website. After you refuse to accept the targeted push service, we will delete or anonymize this part of your personal information, and we will no longer make targeted push based on your personal information when providing business functions to you.

(七) Responding to your above request
For security reasons, we may require that you verify your identity before processing your request. You may be required to provide a written request, or otherwise identify yourself. For all requests made by you under this article, after receiving your request and verifying your user identity, we will, in principle, give feedback within fifteen days. In principle, we will respond positively to your reasonable requests without charging any fees, but for those unreasonable repetitions that require too many technical means (for example, the need to develop a new system or fundamentally change the current practice), bring about the legitimate rights and interests of others Risky or very unrealistic requests, we may reject them. In the following cases, according to the requirements of laws and regulations, we will not be able to respond to your above request:
● In connection with our performance of our obligations under laws and regulations;
● Those directly related to national security and national defense security;
● Directly related to public safety, public health, and major public interests;
● Directly related to criminal investigation, prosecution, trial and enforcement of sentences;
● We have sufficient evidence to show that you have subjective malice or abuse of rights (such as your request will endanger public safety and the legitimate rights and interests of other collectives or individuals, or your request exceeds the scope that can be covered by general technical means and commercial costs) ;
● In order to protect the life and property of the personal information subject or other individuals, but it is difficult to obtain the consent of the individual;
● Responding to the personal information subject's request will cause serious damage to the legitimate rights and interests of you or other individuals or organizations;
● Involving commercial secrets;
● Other situations stipulated by laws and regulations.

8.How we handle personal information of minors

We attach great importance to the protection of minors' information. ByberPay products and services are mainly for businesses and adults. Before using ByberPay products and services, please ensure that you are a person over the age of 18 (inclusive). If you are a minor under the age of 18, you should ensure that you are accompanied by a parent or other legal guardian to read and agree to this policy before you can use ByberPay products and services and provide us with your personal information.
For the collection of minors' personal information with the consent of parents or other legal guardians, we will only use or disclose minors' personal information when permitted by law, expressly consented by parents or other legal guardians, or necessary to protect minors Disclose this information.
If you are a parent or other legal guardian of a minor, please contact us in a timely manner if you have any questions regarding the use of our products and services by a minor under your custody or the user information provided to us by such minor. We will protect the confidentiality and security of juvenile user information in accordance with relevant laws and regulations and the provisions of this policy. If we find that we have collected personal information about minors without prior verifiable parental or other legal guardian consent, we will delete the data as soon as possible.

9.How we update and revise this policy

We reserve the right to update this policy from time to time. We will not change your rights under this policy without your express consent. If the Privacy Policy is updated, we will notify you by reasonable means (including pop-up prompts, prominently on the page, emailing you, or otherwise) so that you can keep abreast of any changes to this policy.
For material changes, depending on the circumstances, we may also provide a more prominent notice explaining the specific changes to this policy.Significant changes include but are not limited to:
(一) Significant changes have taken place in our service model and business form. Such as the purpose of processing personal information, the type of personal information processed, the way of using personal information, etc.;
(二) The main objects of personal information sharing, transfer or public disclosure have changed;
(三) Significant changes have taken place in your rights to participate in the processing of personal information and the manner in which it is exercised;
If you do not agree to the revised Privacy Policy, you have the right and should immediately stop using ByberPay products and services. If you continue to use ByberPay products and services, you are deemed to have accepted our changes to the terms of this policy.

10.How to contact us

If you have any comments or suggestions on this policy, or even have any disputes arising from this policy, you can contact ByberPay's privacy protection agency through the official website display, and we will arrange for a specialist to provide you with consultation in a timely manner or coordinate and resolve your complaints and appeals. Under normal circumstances, we will reply within fifteen days. In order to ensure that we handle your questions efficiently and give you feedback in a timely manner, you need to submit valid identification, valid contact information, written request and relevant evidence to us, and we will process your request after verifying your identity.

11.Explanation of terms

Certain words used in this policy have the following meanings:

Control: means having the ability, directly or indirectly, to influence the management of the mentioned company, whether through ownership, voting shares, contracts or other legally recognized means.

You: refers to the project party, the project party's customers and other third parties who use our products and services.

Third parties: refers to the suppliers that ByberPay has established cooperative relations with third parties. The supplier is responsible for providing certain specific modules or functions or services in ByberPay products and services.

Personal information: refers to various information recorded by electronic or other means that can identify the identity of a specific natural person or reflect the activities of a specific natural person alone or in combination with other information. Personal information includes personal basic information, personal identity information, personal biometric information, network identification information, personal health and physiological information, personal education and work information, personal property information, personal communication information, contact information, personal online records, personal commonly used equipment information, personal location information, etc. For the avoidance of doubt, personal information includes, but is not limited to, personal sensitive information.

Subject of personal information: refers to the natural person identified by personal information.

Sensitive personal information: refers to personal information that, once leaked, illegally provided or abused, may endanger personal and property safety, easily lead to damage to personal reputation, physical and mental health, or discriminatory treatment. Personal sensitive information includes personal property information, personal health and physiological information, personal biometric information, personal identification information, network identification information, etc.

De-identification: refers to the process of making it impossible to identify the subject of personal information without the aid of additional information through technical processing of personal information.

Anonymization: refers to the process in which the subject of personal information cannot be identified through the technical processing of personal information, and the processed information cannot be recovered.

Device: refers to a device that can be used to access ByberPay products and services, such as a smartphone, etc.

Device identifier: refers to a string of characters programmed into the device by the device manufacturer, which can be used to uniquely identify the corresponding device (such as the SN number of a mobile phone).

IP address: Each device connected to the Internet is assigned a number, called an Internet Protocol (IP) address, and these numbers are usually assigned based on geographic areas.
      
      `
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.page-contain {
  width: 100%;
  padding: 0 15px;
  box-sizing: border-box;
}
.title {
  font-size: 20px;
  font-weight: 600;
}
.content {
  text-align: left;
  width: 100%;
  max-width: 800px;
  margin: auto;
  padding: 50px 0px;
  white-space: pre-wrap;
}
</style>
