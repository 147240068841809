<!--
 * @FileDescription: 
 * @Author: jianguo Wang
 * @Date: 2023-12-14 15:29:31
 * @LastEditors: jianguo Wang
 * @LastEditTime: 2025-01-10 11:19:37
-->
<template>
  <div class="page-contain"  :class="language== 'zh_CN'?'':'page-contain-us'">
    <div class="title">{{ $t('components.thirdPart.170720-0') }}</div>
    <div class="blank-contain">
      <div class="blanks"  :class="language== 'zh_CN'?'':'blanks-us'">
        <div class="tip1" :class="language== 'zh_CN'?'':'tip1-us'">Web3</div>
        <div class="tip2" :class="language== 'zh_CN'?'':'tip2-us'">{{ $t('components.thirdPart.170720-1') }}</div>
        <!-- <div class="btn">阅读更多</div> -->
      </div>
      <div class="blanks" :class="language== 'zh_CN'?'':'blanks-us'">
        <div class="tip1" :class="language== 'zh_CN'?'':'tip1-us'">{{ $t('components.thirdPart.170720-2') }}</div>
        <div class="tip2" :class="language== 'zh_CN'?'':'tip2-us'">{{ $t('components.thirdPart.170720-3') }}</div>
        <!-- <div class="btn">阅读更多</div> -->
      </div>
      <div class="blanks" :class="language== 'zh_CN'?'':'blanks-us'">
        <div class="tip1" :class="language== 'zh_CN'?'':'tip1-us'">{{ $t('components.thirdPart.170720-4') }}</div>
        <div class="tip2" :class="language== 'zh_CN'?'':'tip2-us'">{{ $t('components.thirdPart.170720-5') }}</div>
        <!-- <div class="btn">阅读更多</div> -->
      </div>
    </div>
    <div class="blank-contain">
      <div class="blanks-second">
        <div class="blanks-second-left"><img src="@/assets/w@2x.png" alt="" /></div>
        <div class="second-right">
          <div class="second-title">{{ $t('components.thirdPart.170720-6') }}</div>
          <div class="second-tip">{{ $t('components.thirdPart.170720-7') }}</div>
        </div>
      </div>
      <div class="blanks-second">
        <div class="blanks-second-left"><img src="@/assets/w2@2x.png" alt="" /></div>
        <div class="second-right">
          <div class="second-title">{{ $t('components.thirdPart.170720-8') }}</div>
          <div class="second-tip">{{ $t('components.thirdPart.170720-9') }}</div>
        </div>
      </div>
      <div class="blanks-second last-second">
        <div class="blanks-second-left"><img src="@/assets/w3@2x.png" alt="" /></div>
        <div class="second-right">
          <div class="second-title">{{ $t('components.thirdPart.170720-10') }}</div>
          <div class="second-tip">{{ $t('components.thirdPart.170720-11') }}</div>
        </div>
      </div>
    </div>
    <div class="download-contain">
      <img class="bg-img" src="@/assets/05Bg-en.png" alt="" />
      <div class="info-contain">
        <div class="info-title">{{ $t('components.thirdPart.170720-12') }}</div>
        <div class="info-tip">{{ $t('components.thirdPart.170720-13') }}</div>
        <div class="info-btn"  :class="language== 'zh_CN'?'':'info-btn-us'">
          <el-popover placement="bottom" title="" width="120" trigger="hover" content="" @show="acitve = 1" @hide="acitve = 0" :disabled="env != 'production'">
            <div slot="reference" class="right-contain-tip3" :class="acitve == 1 ? 'hover1' : ''" @click="toDownload(2)">
              <img src="@/assets/ios-icon02.png" class="btn-icon" alt="" />
              <!-- <img v-else src="@/assets/ios-icon01.png" class="btn-icon" alt="" /> -->
              <div class="ios-btn">
                <div class="ios-title1">Download on the</div>
                <div class="ios-title2">App Store</div>
              </div>
            </div>
            <img style="width: 150px; height: 150px" src="@/assets/code-ios.png" alt="" />
          </el-popover>
          <!-- <el-popover placement="bottom" title="" width="120" trigger="hover" content="" @show="acitve = 2" :disabled="env != 'production'">
            <div slot="reference" class="right-contain-tip4 and-btn" :class="acitve == 2 ? 'hover2' : ''" @click="toDownload(1)">
              <img src="../assets/goo@2x.png" alt="" />
              <div class="btn-text">
                <div class="ios-tip01">GET IT ON</div>
                <div class="ios-tip02">GOOGLE PLAY</div>
              </div>
            </div>
            <img style="width: 150px; height: 150px" src="@/assets/code-android.png" alt="" />
          </el-popover> -->
          <el-popover placement="bottom" title="" trigger="hover" content="" @show="acitve = 3" @hide="acitve = 0" popper-class="poppers" :disabled="env != 'production'">
            <div slot="reference" class="right-contain-tip4" style="margin-left: 16px" :class="acitve == 3 ? 'hover2' : ''" @click="toDownload(3)">
              <!-- <img v-if="acitve == 1" src="@/assets/anz-icon01.png" class="btn-icon" alt="" />
                <img v-else src="@/assets/anz-icon02.png" class="btn-icon" alt="" /> -->
              <img  src="../assets/down.png" class="btn-icon" alt="" />
              <!-- <img src="../assets/down2.png" class="btn-icon" alt="" /> -->
              {{ $t('components.thirdPart.170720-14') }}
            </div>
            <img style="width: 150px; height: 150px" src="@/assets/apk.png" alt="" />
          </el-popover>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "thirdPart",
  props: {
    msg: String,
  },
  data() {
    return {
      acitve: 0,
      env: process.env.NODE_ENV,
      language: 'en-US'
    };
  },
  mounted() {
    this.language = localStorage.getItem("bpHomeLanguage");
  },
  methods: {
    toDownload(type) {
      if (type == 1) {
        // 谷歌
        if (process.env.NODE_ENV == "production") location.href = "https://play.google.com/store/apps/details?id=com.bytelink.cregispay.card";
      } else if (type == 2) {
        // 苹果
        if (process.env.NODE_ENV == "production") location.href = "https://apps.apple.com/hk/app/byberpay/id6572298569";
      } else {
        if (process.env.NODE_ENV == "production") {
          location.href = "https://file.byberpay.org/app/ByberPay.apk";
        } else {
          location.href = "https://file.byberpay.cc/app/ByberPay.apk";
        }
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="./thirdPartCss/max.css" scoped></style>
<style src="./thirdPartCss/middle.css" scoped></style>
<style src="./thirdPartCss/flat.css" scoped></style>
<style src="./thirdPartCss/min.css" scoped></style>
<style scoped></style>
