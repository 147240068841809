<!--
 * @FileDescription: 
 * @Author: jianguo Wang
 * @Date: 2023-12-14 15:29:31
 * @LastEditors: jianguo Wang
 * @LastEditTime: 2025-01-10 13:54:27
-->
<template>
  <div class="background-contain">
    <div class="header-block"></div>
    <div class="home-page">
      <!-- 头部 -->
      <div class="header">
        <img src="@/assets/Unoo-logo@3x.png" alt="" />
        <div class="header-btn-contain">
          <!-- <div class="header-btn1" @click="toUrl(1)">登录</div> -->
          <!-- <div class="header-btn2" @click="toUrl(2)">注册</div> -->
          <!-- <el-dropdown placement="bottom">
            <span class="el-dropdown-link">
              {{language}}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native=changeLanguage(2)>English</el-dropdown-item>
              <el-dropdown-item @click.native=changeLanguage(1)>简体中文</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "headerPart",
  props: {
    msg: String,
  },
  data() {
    return {
      language: 'Englist',
      toUrl(type) {
        if (type == 1) {
          location.href = "https://platform.hkyoucard.com/ ";
        } else {
          location.href = "https://platform.hkyoucard.com/#/register";
        }
      },
    };
  },
  mounted() {
    this.language = localStorage.getItem("bpHomeLanguage") == 'zh-CN'?'简体中文': 'English'
  },
  methods: {
    changeLanguage(type) {
      if (type == 1) {
        localStorage.setItem("bpHomeLanguage","zh-CN")
        this.language = "简体中文"
        this.$i18n.locale = "zh-CN";
      } else {
        localStorage.setItem("bpHomeLanguage","en-US")
        this.language = "English"
        this.$i18n.locale = "en-US";
      }
      console.log(type)
      console.log(this.language)
      console.log(localStorage.getItem("bpHomeLanguage"))
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="./headerCss/max.css" scoped></style>
<style src="./headerCss/middle.css" scoped></style>
<style src="./headerCss/flat.css" scoped></style>
<style src="./headerCss/min.css" scoped></style>
<style scoped></style>
